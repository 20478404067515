import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { FaLock } from "react-icons/fa"
import { isLoggedIn, handleLogin } from "../services/auth"
import Header from "./header"
import "./layout.css"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  const [loggedIn, setLoggedIn] = useState(false)
  const [password, setPassword] = useState("")
  const [resp, setResp] = useState(null)
  const [clicked, setClicked] = useState(false)
  const handlePassword = e => {
    setPassword(e.target.value)
  }
  const handleLoginButton = password => {
    const response = handleLogin({ password })

    setResp(!!response)
    setClicked(true)
  }
  useEffect(() => {
    setLoggedIn(isLoggedIn())
  }, [password, resp, clicked])
  //console.log(process.env.GATSBY_STATIC_FORM_ACCESS_KEY)
  return (
    <>
      {loggedIn ? (
        <>
          <Header
            siteTitle={data.site.siteMetadata?.title || `Title`}
            loggedIn={loggedIn}
          />
          <div
            style={{
              margin: `0 auto`,
              maxWidth: 960,
              padding: `0 1.0875rem 1.45rem`,
            }}
          >
            <main>{children}</main>

            <footer
              style={{
                marginTop: `2rem`,
              }}
            ></footer>
          </div>
        </>
      ) : (
        <div
          style={{
            display: "flex",
            height: "100vh",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div>
            <h2 className="private-site">This site is private</h2>
            <form
              className="form-container"
              action="#"
              onSubmit={e => {
                e.preventDefault()
                handleLoginButton(password)
              }}
            >
              <div className="password-container">
                <span>
                  <FaLock />
                </span>
                <input
                  type="password"
                  name="password"
                  value={password}
                  onChange={e => {
                    handlePassword(e)
                    setClicked(false)
                  }}
                />
              </div>
              {clicked && !resp && (
                <p style={{ marginTop: "-1.25rem", marginBottom: "1rem" }}>
                  password is incorrect
                </p>
              )}
              <button
                className="login-button"
                onClick={() => handleLoginButton(password)}
              >
                Enter now
              </button>
            </form>
          </div>
        </div>
      )}
    </>
  )
}


export default Layout

export const isBrowser = () => typeof window !== "undefined"
export const getUser = () =>
  isBrowser() && window.localStorage.getItem("mUser")
    ? JSON.parse(window.localStorage.getItem("mUser"))
    : {}
const setUser = user =>
  window.localStorage.setItem("mUser", JSON.stringify(user))
export const handleLogin = ({ password }) => {
  const TEMP = process.env.GATSBY_TEMP_PASSWORD
  const MAIN = process.env.GATSBY_MAIN_PASSWORD
  if (password === TEMP) {
    console.log({ password })
    return setUser({
      login: true,
      temp: true,
    })
  }
  if (password === MAIN) {
    console.log({ password })
    return setUser({
      login: true,
      temp: false,
    })
  }
  return false
}
export const isLoggedIn = () => {
  const user = getUser()
  return !!user.login
}
export const isTempUser = () => {
  const user = getUser()
  return !!user.temp
}
export const logout = callback => {
  setUser({ login: false })
  callback()
}

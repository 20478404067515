import React from "react"
import "./project.scss"
import { FaArrowCircleRight, FaEye, FaGithub } from "react-icons/fa"

const Project = ({ data }) => {
  return (
    <div className="project">
      <div className="wrapper">
        <div className="imgContainer">
          <img src={data.img} alt="" />
        </div>
      </div>

      <div className="projectContent">
        <h3>{data.name}</h3>
        <div className="divider" />
        <p className="projectDesc">{data.desc}</p>
        <div className="divider" />
        <div className="tech-used">
          <p className="learned">
            <strong>Learned: </strong>
            {data.learned}
          </p>
          <p className="technologiesUsed">
            <strong>Tech Used: </strong>
            {data.tech}
          </p>
        </div>
        <div className="divider" />
        <div className="meta-data">
          <a href={data.code}>
            <FaGithub />
          </a>
          <a href={data.preview}>
            <FaEye />
          </a>
          <a href="/">
            <FaArrowCircleRight />
          </a>
        </div>
      </div>
    </div>
  )
}

export default Project

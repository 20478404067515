import { Link } from "gatsby"
import React from "react"
import { logout } from "../services/auth"

const Header = ({ loggedIn }) => {
  return (
    <header
      style={{
        background: `#fff`,
        marginBottom: `1rem`,
      }}
    >
      <div
        style={{
          margin: `0 auto`,
          maxWidth: 960,
          padding: `0.5rem 1.5rem`,
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Link
          to="/"
          style={{
            color: `white`,
            textDecoration: `none`,
          }}
        >
          <img
            src="/red.svg"
            height="32px"
            width="auto"
            alt="Mourya Prasanth"
          />
        </Link>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "#1f1f1f",
            gap: "1rem",
          }}
        >
          <Link
            to="/about"
            style={{
              textDecoration: `none`,
            }}
          >
            About
          </Link>
          <Link
            to="/blog"
            style={{
              textDecoration: `none`,
            }}
          >
            Blog
          </Link>
          <Link
            to="/contact"
            style={{
              textDecoration: `none`,
            }}
          >
            Contact
          </Link>
          <button
            className="logout-button"
            onClick={() =>
              !loggedIn
                ? () => {}
                : logout(() => {
                    window?.location.reload()
                  })
            }
          >
            {!loggedIn ? "Login" : "Logout"}
          </button>
        </div>
      </div>
    </header>
  )
}

export default Header

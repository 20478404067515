import React from "react"
import Layout from "../components/layout"
import Project from "../components/project"
import projects from "../projects.json"
const IndexPage = () => {
  return (
    <Layout>
      <header>
        <div>
          <h2>Hello there, I'm Mourya Prasanth.</h2>
          <h3>Frontend Developer,also an UX and JAMstack enthusiast </h3>
          <p>
            In the ever expanding web of internet, making a very minute number of websites a bit more user friendly and accessible. Also helping them navigate through a sea of information a teenie tiny bit easier as hard as I can.  
          </p>
        </div>
        <img src="https://placehold.it/200" alt="" />
      </header>
      <section id="work">
        {projects.map(project => (
          <Project data={project} />
        ))}
      </section>
      <section id="contact"></section>
      <footer>Copyrights not reserved. can copy freely</footer>
    </Layout>
  )
}

export default IndexPage
